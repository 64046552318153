import React from 'react'
import classNames from 'classnames'
import Image from 'next/image'
import { useSelector } from 'react-redux'
import Link from 'next/link'
import Button from 'components/Button'
import useTranslation from 'translations/hooks/useTranslation'
import TrackingUtils from 'utils/tracking'
import Emoji from 'components/Emoji'
import type { IStoreState } from 'redux/reducers'

interface IProps {
  topPosition?: boolean
  renderWithoutFold?: boolean
}

const FreelancerHomepage: React.FC<IProps> = ({ topPosition, renderWithoutFold }) => {
  const { t } = useTranslation()
  const freelancersCount = useSelector(
    (state: IStoreState) => state.stats?.freelancers_count?.value
  )

  return (
    <div
      className={classNames('ad-hp', {
        'ad-hp--top': topPosition,
        'ad-hp--without-fold': renderWithoutFold,
      })}
    >
      <div className="container relative">
        <div className="freelancers__pricing-wraper">
          <div>
            <h3
              className="heading-2-5"
              dangerouslySetInnerHTML={{
                __html: freelancersCount
                  ? t('freelancers.hpUpsellHeading', {
                      count: freelancersCount,
                    })
                  : t('freelancers.hpUpsellHeadingWithoutNumber'),
              }}
            />
            <Button
              text={t('freelancers.showFreelancers')}
              className="ad-hp__button"
              href="/freelancers"
              color="green"
              onClick={() => {
                TrackingUtils.event('hp-freelancers-banner-cta')
              }}
            />
          </div>
          <div className="freelancers__testimonial freelancers__testimonial--small">
            <div className="freelancers__testimonial-flex">
              <Image
                width={80}
                height={80}
                alt={t('freelancers.testimonial.1.name')}
                className="freelancers__testimonial-photo"
                src="/images/freelancers/testimonials/1.png"
              />
              <p className="freelancers__testimonial-text freelancers__testimonial-text--small">
                {t('freelancers.testimonial.1.textShort')}
              </p>
            </div>
            <p>
              <span className="pill pill--super-small pill--white">
                <Emoji>{t('freelancers.pro.label')}</Emoji>
              </span>
              <br />-{' '}
              <Link href="/freelancers/detail/-9OLs2hmY">
                {t('freelancers.testimonial.1.name')}
              </Link>
              , {t('freelancers.testimonial.1.position')}
            </p>
          </div>
          <div className="freelancers__testimonial freelancers__testimonial--small">
            <div className="freelancers__testimonial-flex">
              <Image
                width={80}
                height={80}
                alt={t('freelancers.testimonial.2.name')}
                className="freelancers__testimonial-photo"
                src="/images/freelancers/testimonials/2.png"
              />
              <p className="freelancers__testimonial-text freelancers__testimonial-text--small">
                {t('freelancers.testimonial.2.textShort')}
              </p>
            </div>
            <p>
              <span className="pill pill--super-small pill--white">
                <Emoji>{t('freelancers.pro.label')}</Emoji>
              </span>
              <br />-{' '}
              <Link href="/freelancers/detail/23xS4AhY8">
                {t('freelancers.testimonial.2.name')}
              </Link>
              , {t('freelancers.testimonial.2.position')}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FreelancerHomepage
