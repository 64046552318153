import dayjs from 'dayjs'
import type { ICompany } from 'redux/interfaces/company'

export default class CompanUtils {
  static removeCompanyType(companyName) {
    return companyName.replace(
      /,?\s*(llc|inc|co|s.r.o|a.s|v.o.s|k.s|spol. s r.o|gmbh|ltd|se)\.?$/i,
      ''
    )
  }
  static normalizeCompanyData(companyRawData: ICompany) {
    if (!companyRawData) {
      return {
        companyName: '',
        companyIco: '',
        companyDic: '',
        companyIcDph: '',
        companyStreet: '',
        companyCity: '',
        companyPostcode: '',
        companyCountry: '',
        companyLogo: '',
        companyAbout: '',
        companyWeb: '',
        companyInstagram: '',
        companyFacebook: '',
        companyLinkedin: '',
        companyTwitter: '',
        companyTelephone: '',
        companyCategories: [],
        outsideEu: false,
      }
    }

    return {
      companyName: companyRawData.name || '',
      companyIco: companyRawData.ico || '',
      companyDic: companyRawData.dic || '',
      companyIcDph: companyRawData.icdph || '',
      companyStreet:
        companyRawData && companyRawData.address ? companyRawData.address.street : '',
      companyCity:
        companyRawData && companyRawData.address
          ? companyRawData.address.location.name
          : '',
      companyPostcode:
        companyRawData && companyRawData.address
          ? companyRawData.address.postal_code
          : '',
      companyCountry:
        companyRawData && companyRawData.address
          ? companyRawData.address.location.country.name
          : '',
      companyLogo:
        companyRawData && companyRawData.about ? companyRawData.about.logo : '',
      companyAbout:
        companyRawData && companyRawData.about ? companyRawData.about.text : '',
      companyWeb: companyRawData && companyRawData.about ? companyRawData.about.web : '',
      companyInstagram:
        companyRawData && companyRawData.about ? companyRawData.about.instagram : '',
      companyFacebook:
        companyRawData && companyRawData.about ? companyRawData.about.facebook : '',
      companyLinkedin:
        companyRawData && companyRawData.about ? companyRawData.about.linkedin : '',
      companyTwitter:
        companyRawData && companyRawData.about ? companyRawData.about.twitter : '',
      companyTelephone: companyRawData?.about?.telephone ?? '',
      companyCategories: companyRawData?.categories ?? [],
      outsideEu: companyRawData?.outsideEu || false,
    }
  }

  static getCountrySlug = (company: ICompany) => {
    return company?.address?.location?.country?.slug
  }

  static isHot = (company: ICompany) => {
    return (
      company?.hot?.promoCode?.end_date &&
      dayjs(company.hot.promoCode.end_date).isBefore(dayjs().add(1, 'year'))
    )
  }
}
